// Config init...
var _cfg = _config || {};

var cls = {
    log : function( _output ){
        if( app.debug ){
            console.log(_output);
        }
    }
};


(function () {
"use strict";
window.app || (window.app = {});
app.modules || (app.modules = {});

app.modules.ajaxInclude = (function () {
    function ajaxIncludeCallback() {
        var includedContainer = $(this).next();
        var targetContainer;




        if ($(this).is('[data-ajax-target]')) {
            targetContainer = $($(this).data('ajax-target'));
            if (targetContainer.length > 0) {
                includedContainer = targetContainer.next();
            }
        } else if ($(this).is('[data-target]')) {
            targetContainer = $($(this).data('target'));
            if (targetContainer.length > 0) {
                includedContainer = targetContainer.next();
            }
        }

        $(this).off('ajaxInclude');

        app.initModules(includedContainer);


        if (_config && _config['debug']) {
            console.warn('ajax included: ', includedContainer);
        }
    }

    return function ($scope) {
        var ajaxIncludeElements = $scope.find('.js-ajaxInclude');
        var ajaxIncludeOptions = {
            device: matchMedia('(max-width: 767px)').matches ? 'mobile' : 'desktop'
        };

        ajaxIncludeElements.not('[data-interaction=click]').on('ajaxInclude', ajaxIncludeCallback).extendAjaxInclude(ajaxIncludeOptions);

        ajaxIncludeElements.filter('[data-interaction=click]').on('click', function(){
            $(this).children('.loading').attr('hidden', false);

            $(this).removeAttr('data-interaction');
            $(this).on('ajaxInclude', ajaxIncludeCallback).extendAjaxInclude({
                device: matchMedia('(max-width: 767px)').matches ? 'mobile' : 'desktop'
            });
        });

    };

}());


app.modules.isClickable = (function () {
    function isClickable( obj, newTab ){
        var $this = obj,
            link = $this.find('a:first'),
            href = link.attr('href'),
            target = link.attr('target');
        if( target == '_blank' || newTab ){
            window.open( href );
        }else{
            window.location.href = href;
        }
    }

    function initScope($scope) {
        $scope.find('.isClickable').on('click', function(evt){
            if (!$(evt.target).is('a')) {
                isClickable( $(this));
            }
        });
    }


    return initScope;
}());

app.modules.timelineHistory = function ($scope) {
    $scope.find('.js-setParam').on('click', function() {
        var $this = $(this);

        if (window.location.href.indexOf($this.data('parameter')) < 0) {
            if (history.pushState) {
                var newurl = window.location + (window.location.href.indexOf('?') >= 0 ? '&' : '?') + $this.data('parameter') + '=1';
                window.history.pushState({path:newurl},'',newurl);
            }
        }
    });
    $scope.find('.js-removeParam').on('hide.bs.collapse', function () {
        var $this = $(this);
        if (window.location.href.indexOf($this.data('parameter')) >= 0) {
            if (history.pushState) {
                var newurl = window.location.href;
                var removeParam = (window.location.href.indexOf('&' + $this.data('parameter')) >= 0 ? '&' : '?') + $this.data('parameter') + '=1';
                newurl = newurl.replace(removeParam, '');
                window.history.pushState({path:newurl},'',newurl);
            }
        }
    });
};

app.mobileNav = function () {
    if(matchMedia('(max-width: 767px)').matches) {
        var mobileNavPlaceholder = $('#mobile-nav-placeholder'),
            mobileNav = $('#main-nav').clone();

        mobileNavPlaceholder.replaceWith(mobileNav);

        $('.js-navbar-toggle').on('click', function () {
            if(!$(this).hasClass('is-open')){
                $('.mobile-nav').addClass('is-open');
                $(this).addClass('is-open');
                $('html').addClass('has-overflow-hidden');
            }else {
                $('.mobile-nav').removeClass('is-open');
                $(this).removeClass('is-open');
                $('html').removeClass('has-overflow-hidden');
            }
        }).on('click', function (evt) {
            evt.preventDefault();
        });

        mobileNav.find('.js-toggle-subnav').on('click', function(evt){
            evt.preventDefault();
            if($(this).closest('li').hasClass('is-open')){
                $(this).closest('li').removeClass('is-open');
                $(this).closest('.mobile-nav__content').removeClass('has-open-subnav')
            }else {
                $(this).closest('ul').children().each(function () {
                    $(this).removeClass('is-open');
                    $(this).closest('.mobile-nav__content').removeClass('has-open-subnav')
                });
                $(this).closest('li').addClass('is-open');
                $(this).closest('.mobile-nav__content').addClass('has-open-subnav')
            }
        });
    }
};


app.modules.expandSearch = function($scope){
    $scope.find('.js-expand-search').on('click', function(evt){
        var expandContainer = $(this).closest('.expand-search');
        if(!expandContainer.hasClass('is-expanded')){
            evt.preventDefault();
            expandContainer.addClass('is-expanded');
            expandContainer.children('.expand-search__form').focus();

            $('body').on('click', bodyClickHandler);
        }
    });

    $scope.find('.expand-search__form').on('focus', function(){
        $(this).prop('aria-hidden', false);
    }).on('blur', function(){
        $(this).prop('aria-hidden', true);
    });

    function bodyClickHandler (evt) {
        if ($(evt.target).parents('.expand-search').length < 1) {
            evt.preventDefault();
            $('.expand-search').removeClass('is-expanded');
            $('body').off('click', bodyClickHandler);
        }
    }
};

app.modules.videoJs = function ($scope) {
    app.modules.videoJs.id || (app.modules.videoJs.id = 1);
    $.loadScript('/static/build/js/libs/video.js').done(function (){
        $scope.find('.js-video-js').each(function () {
            var id = $(this).prop('id');
            if (!id) {
                id = 'video-js-id-' + app.modules.videoJs.id++;
                $(this).prop('id', id);
            }
            videojs(id);
        });
    });
};

app.modules.lightbox = function ($scope) {
    $.loadScript('/static/build/js/libs/lightgallery.min.js').done(function () {

        var videoJsBool = false;
        if($scope.find('.js-lightbox--video')){
            $.loadScript('/static/build/js/libs/lg-video.min.js');
            $.loadScript('/static/build/js/libs/video.js');
            videoJsBool = true;
        }

        $scope.find('.js-lightbox').each(function () {
            $(this).lightGallery({
                selector: '.js-lightbox__item',
                download: false,
                videojs: videoJsBool,
                prevHtml: "<span class='icon icon-arrow-line icon-rotate-180'></span>",
                nextHtml: "<span class='icon icon-arrow-line'></span>"
            });
        });

    });
};

app.modules.view3D = function ($scope) {
    var $modal = $scope.find($(".js-view-3D").data('target'));
    if ($modal && $modal.length){
        $modal.on("show.bs.modal", function(e) {
            var $link = $(e.relatedTarget),
                $iframe = $(this).find(".js-view-3d__iframe");

            if($iframe && $iframe.length){
                if ($iframe.attr('src') != $link.data("src")){
                    $iframe.attr("src", $link.data("src"));
                }
                // $iframe[0].contentWindow.location.reload();
            }
        });
    }
};

app.modules.slider = function ($scope) {
    var $sliders = $scope.find('.js-slider');
    $.loadScript('/static/build/js/libs/slick.min.js').done(function(){
        var slickOptions = {
            slidesToShow: 1,
            nextArrow: '<button type="button" class="slick-next slider__arrow btn-unstyled" aria-label="Next"><span class="icon icon-arrow"></span></button>',
            prevArrow: '<button type="button" class="slick-prev slider__arrow btn-unstyled" aria-label="Previous"><span class="icon icon-arrow"></span></button>',
            dots: false
        };

        $sliders.each(function () {
            var $this = $(this),
                options = $.extend({}, slickOptions);

            if ($this.hasClass('js-news-slider')) {
                $.extend(options, {
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                dots: true
                            }
                        }
                    ]
                });
            }

            if ($this.hasClass('js-header-slider--mobile')) {
                $.extend(options, {
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                arrows: false,
                                dots: true
                            }
                        }
                    ]
                });
            }

            $this.slick(options);
        });
    });
};

app.modules.imageSlider = function ($scope) {
    var $sliders = $scope.find('.js-image-slider');
    $.loadScript('/static/build/js/libs/slick.min.js').done(function(){
        var slickOptions = {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '240px',
            nextArrow: '<div class="arrows-overlay arrow-next"><button type="button" class="slick-next slider__arrow btn-unstyled" aria-label="Next"><span class="icon icon-arrow"></span></button></div>',
            prevArrow: '<div class="arrows-overlay arrow-prev"><button type="button" class="slick-prev slider__arrow btn-unstyled" aria-label="Previous"><span class="icon icon-arrow"></span></button></div>',
            dots: false,
            draggable: false,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        centerPadding: '0px'
                    }
                }
            ]
        };

        $sliders.each(function () {
            var $this = $(this),
                options = $.extend({}, slickOptions);
            $this.slick(options);
        });

        $scope.find('.image-slider__item.slick-cloned').each(function () {
           $(this).find('.lightbox__item').removeClass('js-lightbox__item');
        });

    });
};

app.modules.timelineMarker = function($scope) {
    var timeline = $scope.find('.js-detail-timeline');

    timeline.each(function() {
        var $this = $(this),
            container = $this.closest('.panel-body').find('.container'),
            firstChild = container.children().first(),
            lastChild = container.children().last();

        if(firstChild.hasClass('eps-tl__teaser')) {
            var panelBody = $this.closest('.panel-body'),
                marker = firstChild.find('.eps-tl__teaser__content');

            var difference = marker.closest('.eps-tl__teaser').outerHeight() / 2 - 30 - marker.outerHeight() / 2 ;
            // console.log((marker.closest('.epl-timeline__teaser').outerHeight() - marker.outerHeight()) /2);
            console.log(marker.closest('.eps-tl__teaser').outerHeight());
            // console.log(marker.outerHeight());
            // console.log('marker', firstChild.find('.eps-tl__teaser__content').offset().top);
            // console.log('difference', difference);
            $this.css({'top': difference});
        }
    });
};

app.modules.actionChanger = function ($scope) {
    $scope.find('.js-action-changer').on('submit', function () {
        var newAction = $(this).data('action');

        if (newAction) {
            $(this).attr('action', newAction);
        } else {
            $(this).attr('action', '');
        }
    });
};

app.modules.parsley = function ($scope) {
    var $forms = $scope.find('.js-parsley');

    app.loadParsley().done(function () {
        $forms.parsley(app.modules.parsley.options);

        $forms.each(function () {
            var $form = $(this);

            $form.parsley().on('field:error', function() {
                var parsleyId = this.__id__;
                var fieldClass = this.__class__;

                if (fieldClass == 'ParsleyFieldMultiple') {
                    parsleyId = 'multiple-' + this.$element.data('parsley-multiple');
                }

                this.$element.attr({
                    'aria-invalid': "true",
                    'aria-describedby': 'parsley-id-'+ parsleyId
                });
            });

            $form.parsley().on('field:success', function() {
                this.$element.removeAttr('aria-invalid aria-describedby');
            });
        });


    });
};

app.modules.parsley.options = {
    successClass: 'has-success',
    errorClass: 'has-error',
    classHandler: function (_el) {
        return _el.$element.closest('.form-group');
    },
    errorsContainer: function (_el) {
        return _el.$element.closest('.form-group');
    }
};

(function () {
    var promise;

    app.loadParsley = function () {
        if (promise) {
            return promise;
        }

        promise = $.Deferred();

        $.loadScript("/static/build/js/libs/parsley.min.js").done(function () {
            if (_cfg.lang && _cfg.lang !== 'en') {
                $.loadScript("/static/js/libs/i18n/parsley/" + _cfg.lang + ".js").done(function () {
                    promise.resolve();
                }).fail(function () {
                    /*fallback if there is no package*/
                    promise.resolve();
                });
            } else {
                promise.resolve();
            }
        }).fail(function () {
            promise.reject();
        });

        return promise;
    };
}());

app.modules.hotspot = function ($scope) {

    var popover = $scope.find('.js-popover');

    var defaultOptions = {
        html: true,
        placement: 'auto top',
        viewport: function(e){
            var hotspot = this.$element[0];
            return $(hotspot).closest('.js-hotspot-viewport');
        },
        container: 'body'
    };
    popover.popover(defaultOptions);

};

app.touchNav = function () {
    if (matchMedia('(max-width: 767px)').matches) {
        return;
    }

    var mainNavItems = $('.navbar .navbar-nav>li'),
        mainNavLinks = $('.navbar .navbar-nav>li>a');

    mainNavLinks.on('touchend', function (evt) {
        if (window.TouchEvent && evt.originalEvent instanceof TouchEvent) {
            var parent = $(this).parent();

            if (!parent.hasClass('is-open')) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                mainNavItems.removeClass('is-open');
                parent.addClass('is-open');

                $('body').on('click', bodyClickHandler);
            }
        }
    });

    mainNavLinks.on('keydown', function(evt) {
        if(evt.keyCode == 40 || evt.keyCode == 38 || evt.keyCode == 32){
            evt.preventDefault();
            $('#main-nav>li').removeClass('is-open');
            $(this).parent().addClass('is-open');
            $('body').on('keyup', escKeyHandler);
        } else if (evt.keyCode == 9 && !$(this).parent().hasClass('is-open')) {
            mainNavItems.removeClass('is-open');
            $('body').off('keyup', escKeyHandler);
        }
    });

    function escKeyHandler (evt){
        if(evt.keyCode == 27){
            mainNavItems.removeClass('is-open');
            $('body').off('keyup', escKeyHandler);
        }
    }

    function bodyClickHandler (evt) {
        if ($(evt.target).parents('.navbar-nav').length < 1) {
            evt.preventDefault();
            mainNavItems.removeClass('is-open');
            $('body').off('click', bodyClickHandler);
        }
    }
};

/*
app.modules.gaTracking = function($scope) {
    $scope.find('.js-ga-tracking').on('click', function(){
        if (window['ga']) {
            var category = $(this).data('ga-category'),
                label = $(this).data('ga-label'),
                action = $(this).data('ga-action');

            ga('send', 'event', category, action, label);
        }
    });
};*/

app.scrollTopButton = function () {
    $('.affix-scroll-top-button').affix({
        offset: {
            top: 800,
            bottom: function () {
                return $('.footer').outerHeight(true);
            }
        }
    });
};

app.modules.scrollTo = (function () {
    function scrollTo ($target, callback) {
        if (!$target || !$target.length) {
            console.warn("Can't scroll to '" + target + "'. Target element not found");
            return;
        }

        if (app.affixNavBar && _config.affixNavBar) {
            app.affixNavBar.disable();
        }

        var duration = Math.abs($target.offset().top - window.scrollY) / 8 + 100;

        $('html, body').stop().animate({
                scrollTop: $target.offset().top - 30
            },
            duration,
            'swing', function () {
                if (app.affixNavBar) {
                    app.affixNavBar.enable();
                }

                if (callback) {
                    callback();
                }
            }
        );
    }

    function focus ($target) {
        if (typeof $target.attr('tabindex') == 'undefined') {
            $target.addClass('hide-focus');
            $target.attr('tabindex', '-1');
        }
        $target.focus();
    }

    return function ($scope) {
        $scope.find('.js-scroll-to').on('click', function (evt) {
            evt.preventDefault();
            var targetSelector = $(this).data('scroll-target') || $(this).attr('href');
            var $target = $(targetSelector);

            switch ($(this).data('toggle')) {
                case 'tab':
                    if ($target.hasClass('in')) {
                        scrollTo($target, function () {
                            focus($target);
                        });
                        evt.stopImmediatePropagation();
                        return;
                    }

                    /*scroll to parent because we can't know where the actual child is*/
                    scrollTo($target.parent(), function () {
                        focus($target);
                    });

                    /*update bootstrap nav classes because bootstrap doesn't handle them*/
                    var navTabTrigger = $('.nav [data-toggle="tab"]').filter('[href="' + targetSelector + '"], [data-target="' + targetSelector + '"]');
                    navTabTrigger.each(function () {
                        var $navItems = $(this).closest('.nav').find('li');

                        $navItems.removeClass('active')
                            .find('a').attr('aria-expanded', 'false');

                        $(this).attr('aria-expanded', 'true')
                            .parent().addClass('active');

                    });
                    break;

                case 'collapse':
                    if ($target.hasClass('in')) {
                        scrollTo($target, function () {
                            focus($target);
                        });
                        evt.stopImmediatePropagation();
                        return;
                    }

                    /*scroll to parent because we can't know where the actual child is*/
                    scrollTo($target.parent(), function () {
                        focus($target);
                    });

                    break;

                default:
                    scrollTo($target, function () {
                        focus($target);
                    });
            }
        });
    };
}());


/*app.affixNavBar*/
if (!matchMedia('(max-width: 991px)').matches) {
    (function () {
        // Hide Header on on scroll down
        var didScroll;
        var lastScrollTop = 0;
        var delta = 1;
        var navbarHeight;
        var defaultTopPosition;
        var $navbar;
        var $navbarContainer;
        var isTransitioning = false;
        var isFixed = false;
        var isDisabled = false;

        function scrollHandler() {
            var scrollTopPosition = $(this).scrollTop();
            if (Math.abs(lastScrollTop - scrollTopPosition) <= delta)
                return;

            if (isDisabled) {
                lastScrollTop = scrollTopPosition;
                return;
            }

            var navbarOffset = $navbar.offset().top;

            if (scrollTopPosition <= defaultTopPosition) {
                $navbar.removeClass('is-affix').css('top', 0).removeClass('is-transitioning');
                isFixed = false;
                isTransitioning = true;
                return;
            }

            if (lastScrollTop > scrollTopPosition) {
                /*scroll up*/

                if (!isFixed) {
                    if (isTransitioning) {
                        if (navbarOffset >= scrollTopPosition) {
                            /*end of transtion*/
                            isTransitioning = false;
                            isFixed = true;
                            $navbar.css('top', 0);
                            $navbar.addClass('is-affix');
                            $navbar.removeClass('is-transitioning');
                        }
                    } else {
                        $navbar.addClass('is-transitioning').css({
                            top: scrollTopPosition - navbarHeight
                        });
                        isTransitioning = true;
                    }
                }
            } else if (lastScrollTop < scrollTopPosition) {
                /*scroll down*/

                if (isFixed) {
                    $navbar.removeClass('is-affix');

                    $navbar.css({
                        top: lastScrollTop
                    }).addClass('is-transitioning');

                    isTransitioning = true;
                    isFixed = false;
                }

                if (isTransitioning) {
                    if (navbarOffset + navbarHeight <= scrollTopPosition) {
                        isTransitioning = false;
                    }
                }
            }

            lastScrollTop = scrollTopPosition;
        }

        app.affixNavBar = function () {
            $navbar = $('.js-affix-nav-bar');
            $navbarContainer = $('.js-affix-nav-bar-container');
            defaultTopPosition = $navbarContainer.offset().top;

            navbarHeight = $navbar[0].getBoundingClientRect().height; /*get exact height (jquery returns rounded value)*/
            $navbarContainer.height(navbarHeight);

            $(window).scroll(function (event) {
                didScroll = true;
            });

            requestAnimationFrame(function animationFrameHandler() {
                if (didScroll) {
                    scrollHandler();
                    didScroll = false;
                }
                requestAnimationFrame(animationFrameHandler);
            });
        };

        app.affixNavBar.disable = function (disableAtAffixState) {
            if (disableAtAffixState) {
                $navbar.addClass('is-affix').css('top', 0).removeClass('is-transitioning');
                isFixed = true;
                isTransitioning = false;
            } else {
                $navbar.removeClass('is-affix').css('top', 0).removeClass('is-transitioning');
                isFixed = false;
                isTransitioning = false;
            }

            isDisabled = true;
        };

        app.affixNavBar.enable = function () {
            isDisabled = false;
        };

        app.affixNavBar.getAffixNavBarHeight = function () {
            return $('.js-affix-nav-bar').height() || 0;
        };

        app.affixNavBar.isInDefaultState = function () {
            return !$navbar.hasClass('is-transitioning') && !$navbar.hasClass('is-affix');
        };


    }());
}

app.initModules = function ($scope) {
    if(!$scope) {
        $scope = $('body');
    }

    if (_cfg['_preload']) {
        $.each( _cfg['_preload'], function( _key, _val ){
            if( _cfg['_preload'][_key] && app.modules[_key] && typeof window.app.modules[_key] == 'function' ){
                cls.log('init module: ' + _key);
                window.app.modules[_key]($scope);
            }
        });
    }

    //init all modules if the right _cfg key is set for them
    for (var module in _cfg) {
        if (_cfg[module] && app.modules[module] && typeof  app.modules[module] === 'function') {
            cls.log('init module: ' + module);
            app.modules[module]($scope);
        }
    }

    if (_cfg['_reload']) {
        $.each( _cfg['_reload'], function( _key, _val ){
            if( _cfg['_reload'][_key] && app.modules[_key] && typeof window.app.modules[_key] == 'function' ){
                cls.log('init module ' + _key);
                window.app.modules[_key]($scope);
            }
        });
    }
};

function isFocusable( $el ) {
    var tabIndex = +$el.attr( "tabindex" );
    tabIndex = isNaN( tabIndex ) ? -1 : tabIndex;
    return $el.is( ":input, a[href], area[href], iframe" ) || tabIndex > -1;
}
/*app.toc*/
(function () {
    var id = 0;
    function addTocEntry (text, anchorId) {
        $tocList.append('<li><a class="sr-only sr-only-focusable" href="#' + anchorId + '">' + text + '</a></li>');
    }

    var $tocList;
    app.toc = function () {

        $tocList = $('.js-toc__list');
        if (!$tocList || !$tocList.length) {
            console.warn('can\'t render Table of content. No js-toc__list found');
        }
    };

    app.modules.toc = function($scope) {
        $scope.find('.js-toc__title').each(function () {
            var $title = $(this);
            var anchorId = 'toc-entry-target-' + id;
            $title.attr('id', 'toc-entry-target-' + id);
            if (!isFocusable($title)) {
                $title.attr('tabindex', '-1');
            }
            id++;
            addTocEntry($title.text(),anchorId);
        });
    }
}());

app.googleMap = (function () {
    var apiLoaded = false,
        apiLoading = false,
        callbacks = [];

    function loadScript() {
        apiLoading = true;
        var script = document.createElement("script");
        script.type = "text/javascript";

        if (_cfg['googleMapAPIKey']) {
            script.src = "https://maps.googleapis.com/maps/api/js?key=" + _cfg.googleMapAPIKey + "&callback=app.googleMap.apiLoadedCallback&libraries=places";
        } else {
            console.log("google maps API key is missing")
        }
        document.body.appendChild(script);
    }

    function apiLoadedCallback() {
        for(var i= 0, callback; callback = callbacks[i]; i++) {
            callback();
        }

        apiLoading = false;
        apiLoaded = true;
    }

    function loadApi(callback) {
        if (apiLoaded) {
            callback();
        } else {
            callbacks.push(callback);

            if (!apiLoading) {
                loadScript();
            }
        }
    }

    return {
        apiLoadedCallback: apiLoadedCallback,
        loadApi: loadApi
    };
}());

app.modules.googleMap = (function () {
    function GoogleMap (element) {
        var self = this;
        this.element = $(element);
        this.canvas = element.find('.js-google-map__canvas')[0];
        this.mapInfo = element.find('.js-map-info');
        if (!this.mapInfo.length) {
            this.mapInfo = $('.js-map-info');
        }
        this.mapInfoAjaxAction = this.mapInfo.data('ajax-action');
        this.map = null;
        this.marker = [];
        this.geo = [];


        if(typeof $(this.canvas).data('longitude') != 'undefined'){
            self.geo['longitude'] = $(this.canvas).data('longitude');
            self.geo['latitude'] = $(this.canvas).data('latitude');
        } else {
            self.geo['longitude'] = 12.6370814;
            self.geo['latitude'] = 47.3932376;
        }


        app.googleMap.loadApi(function () {
            self.initMap();
        });



        $.loadScript("/static/build/js/filterform.min.js").done(function () {
            app.filterStore.subscribe(function () {
                var baseUrl = _config.filterStoreUrlMap,
                    address = $('.js-google-autocomplete').val();

                self.fetchMarker(baseUrl);

                // after loading new Markers the Tab should be initalized again (prevents rendering problems)
                self.initTab();

                if(address) {
                    var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address;

                    $.ajax(url, {
                        cache: true,
                        dataType : 'json'
                    }).done(function (data, textStatus, jqXHR) {
                        self.getGeoLocation(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng);

                    }).fail(function (response,errorType,errorText) {
                        console.error('Geolocation error', + response,errorType,errorText)
                    });
                }
            });
        });

    }

    GoogleMap.prototype.initMap = function () {
        var self = this;

        var mapOptions = {
            zoom: 17,
            center: new google.maps.LatLng(self.geo['latitude'], self.geo['longitude']),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            minZoom: 5,
            disableDefaultUI: true
        };

        this.markerOptions = {
            size: new google.maps.Size(40, 58),
            origin: new google.maps.Point(0,0),
            anchor: new google.maps.Point(20, 54),
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: '#fff',
                strokeColor: '#fff',
                strokeWeight: 0,
                fillOpacity: 0,
                scale: 10
            }
        };

        self.map = new google.maps.Map(self.canvas, mapOptions);

        this.element.on('click', '.js-google-map__zoom-in', function () {
            self.map.setZoom(++self.map.zoom);
        });

        this.element.on('click', '.js-google-map__zoom-out', function () {
            self.map.setZoom(--self.map.zoom);
        });

        this.mapInfo.on('click', '.js-map-info__close', function () {
            self.closeMapInfo();
        })

        this.poiData = window[this.element.data('poi')];
        if (this.poiData) {
            this.setMarker(this.poiData);
        }

        // var mapStyle = [
        //
        // ];
        //
        // self.map.set('styles', mapStyle );

        // if(this.element.data('active-listpoi')) {
            $('.js-show-on-map').on('click', function(e) {
                var markerId = $(this).data('poi-id');
                self.activeMarker(markerId);
                e.preventDefault();
            });
        // }

        // if(this.element.data('geolocator')) {
        //     $('.js-google-autocomplete').on('input', function () {
        //         self.autoComplete($(this).attr('id'));
        //     });
        // }

        this.element.data('google-map', this);

        //if map is in accordion
        var accordion = this.element.closest('.collapse');
        if (accordion && accordion.length>0) {
            accordion.one('shown.bs.collapse', function () {
                google.maps.event.trigger(self.map, 'resize');
                self.centerMap();
            });
        }

        //if map is in tab
        this.initTab();

    };

    // checks if map is in a tab and repaints (by triggering resize) the map after changing to the corresponding tab
    GoogleMap.prototype.initTab = function () {
        var self = this,
            tabPanel = this.element.closest('.tab-pane');
        if (tabPanel && tabPanel.length>0) {
            $('a[href="#'+tabPanel.attr('id')+'"]').one('shown.bs.tab', function () {
                google.maps.event.trigger(self.map, 'resize');
                self.centerMap();
            });
        }
    };

    GoogleMap.prototype.setMarker = function (data) {
        var self = this;

        //remove marker
        for (var i = 0, marker; marker = self.marker[i]; i++ ) {
            if(marker.dataId != 'myLocationMarker'){
                marker.setMap(null);
            }
        }

        self.marker = self.marker.filter(function(el){
            return el.dataId == 'myLocationMarker';
        });

        $.loadScript('/static/build/js/libs/markerwithlabel_packed.js').done(function(){
            for (var i= 0, poi; poi = data[i]; i++) {
                if (poi.lat && poi.lng) {
                    // var markerOptions;

                    var poiPosition = new google.maps.LatLng(poi.lat, poi.lng),
                        newMarker = new MarkerWithLabel({
                            map: self.map,
                            position: poiPosition,
                            icon: self.markerOptions.icon,
                            animation: google.maps.Animation.DROP,
                            dataId: poi.id,
                            defaultIcon: self.markerOptions.icon,
                            labelContent: "<div class='google-map__marker text-center'>" +
                            "<span class='icon icon-location'></span>" +
                            "<span class='google-map__marker__circle'></span>" +
                            "</div>",
                            labelAnchor: new google.maps.Point(17, 54),
                            labelClass: 'google-map__marker-wrapper',
                            dataInfoBox: poi.infoBox
                        });

                    self.marker.push(newMarker);
                    self.centerMap(); //need for markerwithlabel

                    google.maps.event.addListener(newMarker, 'click', function() {
                        var isMarkerClick = true;
                        if (this.isActive) {
                            self.deactivateAllMarker();
                        } else {
                            self.deactivateAllMarker();
                            self.activeMarker(this.dataId, isMarkerClick);
                        }
                    });

                    if (poi.infoBox) {
                        (function (poi, newMarker) {
                            google.maps.event.addListener(newMarker, 'click', function() {
                                $.loadScript("/static/build/js/libs/google.maps.infobox.js").done(function () {
                                    self.map.setCenter(newMarker.getPosition());
                                    self.showDetail(poi.id, newMarker);
                                });
                            });
                        }(poi, newMarker));
                    }
                }
            }
        });
    };

    GoogleMap.prototype.fetchMarker = function (baseUrl) {
        var self = this,
            pendingRequest;

        if (pendingRequest) {
            pendingRequest.abort();
        }

        if (!baseUrl) {
            console.warn('GoogleMap.prototype.fetchMarker: baseUrl not defined');
            return true;
        }
        var url = baseUrl + (baseUrl.indexOf('?') < 0 ? '?': '&') + $.param(app.filterStore.getSerializedParams());

        return pendingRequest = $.ajax(url, {
            cache: true,
            dataType : 'json',
            headers: {Ajax: 1, Format: 'json'}
        }).done(function (data, textStatus, jqXHR) {
            pendingRequest = null;
            self.setMarker(data);

            // update number of results
            $('.js-dealer-items-count').html(data.length);
        }).fail(function (response,errorType,errorText) {
            console.log(response,errorType,errorText)
        });
    };


    // GoogleMap.prototype.getGeoLocation = function (lat, lng) {
    //     var latlng = new google.maps.LatLng(lat, lng);
    //
    //     this.setMyLocation(latlng);
    // };

    // GoogleMap.prototype.setMyLocation = function (latlng) {
    //     var self = this;
    //
    //     //remove marker from map
    //     for (var i = 0, marker; marker = self.marker[i]; i++) {
    //         if(marker.dataId == 'myLocationMarker') {
    //             marker.setMap(null);
    //         }
    //     }
    //
    //     self.marker = self.marker.filter(function(el){
    //         return el.dataId !== 'myLocationMarker';
    //     });
    //
    //     $.loadScript('/static/build/js/libs/markerwithlabel_packed.js').done(function() {
    //         var newMarker = new MarkerWithLabel({
    //             map: self.map,
    //             position: latlng,
    //             icon: self.markerOptions.icon,
    //             animation: google.maps.Animation.DROP,
    //             dataId: 'myLocationMarker',
    //             defaultIcon: self.markerOptions.icon,
    //             labelContent: "<div class='google-map__marker google-map__marker--myLocation text-center'>" +
    //             "<span class='icon icon-location-full text-primary'>" +
    //             "<span class='icon icon-coordinates text-white'></span>" +
    //             "</span>" +
    //             "</div>",
    //             labelAnchor: new google.maps.Point(17, 54),
    //             labelClass: 'google-map__marker-wrapper'
    //         });
    //
    //         self.marker.push(newMarker);
    //
    //         var latlngbounds = new google.maps.LatLngBounds();
    //         latlngbounds.extend(latlng);
    //
    //         self.map.setCenter(latlngbounds.getCenter());
    //         self.map.setZoom(10);
    //     });
    //
    // };

    GoogleMap.prototype.centerMap = function () {
        google.maps.event.trigger(this.map, 'resize');
        var latlngs = [];

        for (var i= 0, marker; marker = this.marker[i]; i++) {
            latlngs.push(marker.getPosition());
        }

        // Zoom/center map to fit all marker
        if (latlngs.length === 1) {
            this.map.setCenter(latlngs[0]);
        } else {
            var latlngbounds = new google.maps.LatLngBounds();
            for (var i= 0, pos; pos = latlngs[i]; i++) {
                latlngbounds.extend(pos);
            }
            this.map.setCenter(latlngbounds.getCenter());
            this.map.fitBounds(latlngbounds);
        }
    };

    GoogleMap.prototype.zoomToMarker = function (marker) {
        // this.map.setZoom(17);
        // centerMap to get right zoom
        // this.centerMap();
        // center map for the given marker
        this.map.setCenter(marker.getPosition());
    };

    GoogleMap.prototype.showDetail = function (id, marker) {
        var self = this;

        if (this.detailView && this.detailView.markerId && this.detailView.markerId === id) {
            this.closeDetail();
            return;
        }

        this.closeDetail();

        var detailContent = marker.dataInfoBox;

        var myOptions = {
            content: detailContent
            ,pixelOffset: new google.maps.Size(-272, 40)
            ,boxStyle: {
                width: "545px"
            }
            ,boxClass: "infobox google-map__infobox"
            //,closeBoxURL: "/static/img/content/google-close.png"
        };

        this.detailView = new InfoBox(myOptions);
        this.detailView.open(this.map, marker);

        this.detailView.markerId = id;

        //get next and prev marker
        var routeMarker = this.marker.filter(function (o) {
            return o.routeMarker === true;
        });
        for (var i = 0; i < routeMarker.length; i++) {
            var obj = routeMarker[i];

            if(obj.dataId == marker.dataId){
                var nextIndex = i + 1,
                    prevIndex = i - 1;

                if(i == routeMarker.length - 1){
                    nextIndex = 0;
                }else if (i <= 0){
                    prevIndex = routeMarker.length -1
                }

                this.detailView.addListener("domready", function() {
                    $('.js-infobox-arrow').on('click', function() {
                        if($(this).data('direction') == 'next'){
                            self.map.setCenter(routeMarker[nextIndex].getPosition());
                            self.showDetail(routeMarker[nextIndex], routeMarker[nextIndex]);
                        }else {
                            self.map.setCenter(routeMarker[prevIndex].getPosition());
                            self.showDetail(routeMarker[prevIndex], routeMarker[prevIndex]);
                        }
                    });
                });
            }
        }
    };

    GoogleMap.prototype.closeMapInfo = function () {
        this.element.removeClass('js-has-active-marker');
        this.mapInfo.addClass('hidden');
        this.deactivateAllMarker();
    };

    GoogleMap.prototype.deactivateAllMarker = function() {
        var self = this;

        for (var i = 0, marker; marker = self.marker[i]; i++) {
            marker.isActive = false;
            marker.labelClass = 'google-map__marker-wrapper';
            try {
                marker.label.setStyles(); //Force label to redraw (makes update visible)
            }
            catch (e) {
                // Ignore any exceptions
            }
        }

        $('.js-active-map').removeClass('is-active');
    };

    GoogleMap.prototype.activeMarker = function(markerId, isMarkerClick) {
        var self = this,
            pendingRequest;
        self.deactivateAllMarker();

        for (var i = 0, marker; marker = self.marker[i]; i++) {
            if (marker.dataId == markerId){
                var activeMarker = self.marker[i];

                activeMarker.isActive = true;
                activeMarker.labelClass = 'google-map__marker-wrapper' + ' is-active';
                try {
                    activeMarker.label.setStyles(); //Force label to redraw (makes update visible)
                }
                catch (e) {
                    // Ignore any exceptions
                }

                // change tab
                $('.js-map-tab').tab('show');
                // add class
                self.element.addClass('js-has-active-marker');
                self.mapInfo.removeClass('hidden');

                var poiTeaser = $('.js-dealer-info[data-poi-id=' + markerId+ ']');

                self.mapInfo.html(poiTeaser.clone());
                if (!isMarkerClick){
                    self.zoomToMarker(activeMarker);
                }

                // exit for loop, so other markers aren't checked
                break;
            } else {
                // last marker
                if (i == self.marker.length -1){
                    console.log('there is no marker with the given marker-id');
                }
            }
        }
    };

    function initScope($scope) {
        $scope.find('.js-google-map').each(function () {
            new GoogleMap($(this));
        });
    }

    return initScope;
}());

app.modules.showOnMapLinks = function ($scope) {
    function initScope($scope) {
        var $gMap = $('.js-google-map').data('google-map');
        if ($gMap) {
            $scope.find('.js-show-on-map').on('click', function (e) {
                var markerId = $(this).data('poi-id');
                $gMap.activeMarker(markerId);
                // google.maps.event.trigger($gMap.map, 'resize');
                // console.log('resized');
                e.preventDefault();
            });
        }
    }

    return initScope;
}();

app.socialAddthis = function () {
    setTimeout(function () {
        $('body').append($('<script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5975bd311caa12c9"></script>'));
    }, 500);
};

app.filterForm = function () {
    $.loadScript('/static/build/js/filterform.min.js');
};




app.modules.subSelect = function ($scope) {
    $scope.find('.js-sub-select').each(function () {
        var $subSelect = $(this);
        var $observingInputs = $($subSelect.data('depend-on'));
        var url = $subSelect.data('url');
        var $wrapper = $subSelect.closest('.js-sub-select__wrapper');

        var pendingRequest;
        $observingInputs.on('change', function () {
            var currentValue = $subSelect.val();
            $subSelect.attr('disabled', 'disabled');

            if(pendingRequest) {
                pendingRequest.abort();
            }

            pendingRequest = $.ajax(url, {
                data: $observingInputs.serialize(),
                cache: true,
                dataType: 'html'
            }).done(function (data) {
                $subSelect.empty();
                $subSelect.append(data);

                if (!$.trim(data)){
                    //if the response is blank
                    $wrapper.hide();
                } else {
                    $wrapper.show();
                    $subSelect.val(currentValue);
                    if (!$subSelect.val()) {
                        $subSelect.val($subSelect.find('option:first-child').val());
                    }
                }
            }).always(function () {
                pendingRequest = null;
                $subSelect.attr('disabled', null);
            });
        });
    });
};

// syncs tabs with an accordion, so tab changes also open accordion panels and vice-versa
app.modules.syncedTabs = (function ($scope) {
    function initScope($scope) {
        var accordionId = $scope.find('.js-syncedtabs').data('sync-accordion-id');

        if (!accordionId) {
            console.log('no data-sync-accordion-id for .js-syncedtabs');
            return;
        }
        $scope.find('.js-syncedtabs').find('a').each(function(){
            var $this = $(this),
                $syncedAccPanel = $this.data('sync-accordion-panel');

            if ($syncedAccPanel) {
                // tab clicks change accordion
                $this.on('shown.bs.tab', function (e) {
                // $this.on('click', function (e) {
                    /* ensure any open panels are closed before showing selected */
                    $('#'+accordionId).on('show.bs.collapse', function () {
                        $('#'+accordionId+' .in').collapse('hide');
                    });

                    // console.log('showing ' + $(this).data('sync-accordion'));
                    $('#'+$syncedAccPanel).collapse('show');
                });

                // accordion changes tab
                // $scope.find('[href="#'+$syncedAccPanel+'"]').on('click', function (e) {
                $scope.find('#'+$syncedAccPanel).on('show.bs.collapse', function (e) {
                    // console.log('clicked accordion');
                    // console.log('show', $this);
                    $this.tab('show');
                })

            }

        });

    }

    return initScope;
}());

app.modules.hashTabs = (function ($scope) {
    function initScope($scope) {
        // select tab from url

        // Change hash on tab changes
        $('.js-hashtabs a').on('shown.bs.tab', function (e) {

            if(typeof keyshots == 'object'){

                var selectedElement = e.target;
                var tabidx = $(selectedElement).data('index');
                var button = $('.js-view-3D');

                if(keyshots[tabidx] != null){
                    $(button).data('src', keyshots[tabidx]);
                    $(button).css('display','block');
                } else {
                    //$(button).css('display','none');
                }

            }
            history.replaceState('', '', $(this).data('detailurl'));
        });

        // show the corresponding tab due to the hash
        var paramName = 'type';
        var type = getURLParameter(paramName);
        var tabPath = location.pathname + '?'+paramName+'=' + type;
        if (tabPath) {
            $('.js-hashtabs a[data-detailurl="' + tabPath + '"]').tab('show');
        }

    }

    function getURLParameter(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    }

    return initScope;
}());

})();

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_cfg['_preload']) {
        $.each( _cfg['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _cfg, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_cfg['_reload']) {
        $.each( _cfg['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    app.initModules();

})(jQuery);

